<template>
  <div
    class="transition-max-height duration-500 ease-in-out"
    :class="{
      'max-h-0 hidden': !toggleCollapse,
      'max-h-full': toggleCollapse
    }"
  >
    <slot />
  </div>
</template>

<script>
import useToggle from '@/hooks/toggle.js';
import { onMounted, watch } from 'vue';

export default {
    name: 'BaseCollapse',

    props: {
        modelValue: {
            type: Boolean,
            default: false,
            description: 'bound value for toggling collapse'
        },
        useTransition: {
            type: Boolean,
            default: true,
            description: 'enables/disables transition while togging collapse(enabled by default)'
        }
    },

    setup (props) {
        const { toggle: toggleCollapse, handleToggle: handleCollapseToggle } = useToggle();
        onMounted(() => {
            toggleCollapse.value = props.modelValue;
        });
        watch(
            () => props.modelValue,
            () => {
                if (toggleCollapse.value !== props.modelValue) {
                    toggleCollapse.value = props.modelValue;
                }
            }
        );
        return {
            toggleCollapse,
            handleCollapseToggle
        };
    }
};
</script>
